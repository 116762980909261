<template>
  <div>
    <template v-if="isEntryRoute || isDetailRouteWithoutParameter">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)"/>
    </template>

    <!-- if this is active route (e.g. /orders) -->
    <template v-if="isEntryRoute">
      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(orderstate)="data">
          <b-badge :variant="getOrderStateVariant(data.cell.value)">{{ data.cell.value }}</b-badge>
        </template>

        <template #cell(orderdate)="data">
          {{ data.cell.value ? $d(new Date(data.cell.value), 'compact') : '-' }}
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /orders/detail/:orderid) -->
    <section v-else-if="isDetailRouteWithParameter">
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ $t('components.entity.detailheader.name.label') }}</dt>
            <dd><h1>{{ data.label }}</h1></dd>
          </dl>
        </template>
      </entity-detail-header>

      <router-view/>
    </section>

    <!-- if subroute is active route (e.g. /orders/add) -->
    <router-view v-else-if="isDetailRouteWithoutParameter"/>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN, ORDER_STATES, ORDER_STATES_BADGES_VARIANTS } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import EntityDetailHeader from '@/components/entity/DetailHeader'

const ENTITY_KEY = 'orders'

export default {
  name: 'Orders',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN, 'orderdate'],
            sortable: ['orderdate'],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              orderdate: 'text-right',
              orderstate: 'text-right'
            },
            thClass: {}
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      },
      orderStateBadgeVariants: Object.keys(ORDER_STATES)
        .reduce((variants, sKey) => Object.assign(variants, { [ORDER_STATES[sKey]]: ORDER_STATES_BADGES_VARIANTS[sKey] }), {})
    }
  },
  computed: {
    isEntryRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    isDetailRouteWithParameter () {
      return this.$route.matched[this.$route.matched.length - 1].path.indexOf(`/:${this.eCurrent.paramKey}`) >= 0
    },
    isDetailRouteWithoutParameter () {
      return !this.isEntryRoute && !this.isDetailRouteWithParameter
    }
  },
  methods: {
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    getOrderStateVariant (state) {
      switch (state.toLowerCase()) {
        case 'fehlgeschlagen':
          return 'danger'
        case 'teillieferung':
          return 'warning'
        case 'abgeschlossen':
          return 'success'
        case 'übermittelt':
          return 'info'
        case 'verworfen':
          return 'delete'
        case 'verarbeitet':
          return 'change'
        case 'storniert':
          return 'danger'
        case 'teilstorniert':
          return 'warning'
        default:
          return ''
      }
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
